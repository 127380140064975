import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/SlevI45XK8Y"
    bibleGroupSrc="https://pdfhost.io/v/IB6vDeeol_Microsoft_Word_Bible_Group_Homework_44docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="For Hope - Searching..." />
  </Layout>
)

export default SermonPost
